import React from "react";

const CompanyProfile = () => {
  return (
    <>
      company profile
    </>
  )
}

export default CompanyProfile