import React from "react";

const TeamProfile = () => {
  return (
    <>
      team profile
    </>
  )
}

export default TeamProfile